<template>
  <!-- 新增章节弹窗 -->
  <a-modal :visible="isShow" :title="title" @ok="handleOk('ruleForm')" @cancel="handleCancel">
    <a-form-model ref="ruleForm" :model="formState" :rules="rules" v-bind="layout">
      <a-form-model-item label="上传图片" name="content"  prop="content">
        <UpdateImg @avatarfns="data => formState.content = data[0]" :imageUrlNum="1" :imgUrl="formState.content ? [formState.content] : []" :urlSize="1024" />
        <div style="color: red; margin-top:5px">{{formState.content ? '' : '请上传图片'}}</div>
      </a-form-model-item>
      <a-form-model-item v-if="showRadio" label="按钮跳转类型" prop="btnType">
        <a-radio-group v-model="formState.btnType" >
          <a-radio :value="1">
            圈子
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
     
    </a-form-model>
  </a-modal>
</template>
<script>
import UpdateImg from "@/components/file/updataImg";
import { getCourseModuleContent, setCourseModuleContent } from '@/request/api/coursManage'
export default {
  components: { UpdateImg },
  props: {
    title: {
      type: String,
      default: '内容管理'
    },
    isShow: {
      type: Boolean,
      default: false
    },
    moduleId: {
      type: String,
    },
    showRadio: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      formState: {
        content: '',
        btnType: 0,
      },
      rules: {
        content: [{ required: true, message: "请选择图片", trigger: 'change' }],
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
      },
    }
  },
  watch: {
    moduleId(val) {
      this.formState = this.$options.data().formState
      if (!this.showRadio) {
        delete this.rules.btnType
      } else {
        this.rules.btnType = [{ required: true, message: "请选择跳转类型", trigger: 'change' }]
      }
      if (val && this.moduleId) {
        this.getDetail()
      }
    }
  },
  methods: {
    async getDetail() {
      const { code, data } = await getCourseModuleContent({moduleId: this.moduleId})
      if (code !== 200) return
      const { content, btnType } = data
      this.formState.content = content
      this.formState.btnType = btnType
    },
    async handleOk(formName) {
      if (!this.formState.content) return
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.onSave()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async onSave() {
      const params = {
        moduleId: this.moduleId,
        content: this.formState.content,
        btnType: this.formState.btnType
      }
      const { code } = await setCourseModuleContent(params)
      if (code !== 200) return
      this.$message.success('操作成功')
      this.$emit('close')
    },
    handleCancel() {
      this.formState = this.$options.data().formState
      this.$emit('close')
    }
  }
}
</script>